import { Carousel, Select, Typography, Card, Spin } from 'antd';
import { useGetAllFromKosQuery, useLazyGetAllFromKosQuery } from '../../../store/commonApiKos';
import { useEffect, useState } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
const { Title, Text } = Typography;

function CalenderContent({ SingleCourse }) {
    const [selectedSection, setSelectedSection] = useState();
    const { data, isFetching } = useGetAllFromKosQuery({
        endpoint: 'course_catalogue/section',
        params: {
            course_code: SingleCourse.code,
        },
        urlBase: 'spring/',
    });
    if (isFetching) {
        return <Spin size='large' />;
    }
    const sectionsAvailable = data?.sections?.filter((section) =>
        dayjs(section.end_date).isAfter(dayjs()),
    );
    if (sectionsAvailable?.length === 0) {
        return (
            <Title
                level={5}
                style={{
                    marginTop: '0px',
                }}
            >
                No sections
            </Title>
        );
    }
    return (
        <div>
            <CarouselDataSelector
                allSections={sectionsAvailable ?? []}
                selectedSection={selectedSection}
                setSelectedSection={setSelectedSection}
            />
            {selectedSection ? <SectionFaculty selectedSection={selectedSection} /> : <></>}
            {selectedSection ? <KosCalender selectedSection={selectedSection} /> : <></>}
        </div>
    );
}

function CarouselDataSelector({ allSections, selectedSection, setSelectedSection }) {
    return (
        <Carousel dots={false} slidesToShow={3} slidesToScroll={1} arrows={true} infinite={false}>
            {allSections?.map((SingleSection, index) => {
                if (index === 0 && !selectedSection) {
                    setSelectedSection(SingleSection);
                }
                return (
                    <div key={SingleSection.id}>
                        <Card
                            title={null}
                            bordered={false}
                            classNames={{
                                body:
                                    selectedSection?.id === SingleSection.id
                                        ? 'CarouselCardActive CarouselCardCommon'
                                        : 'CarouselCardInactive CarouselCardCommon',
                            }}
                            onClick={() => setSelectedSection(SingleSection)}
                        >
                            <p className='CarouselCardSection'>{SingleSection.name}</p>
                            <p className='CarouselCardDateRange'>
                                {dayjs(SingleSection.start_date).format('MMMM DD, YYYY')} -{' '}
                                {dayjs(SingleSection.end_date).format('MMMM DD, YYYY')}
                            </p>
                        </Card>
                    </div>
                );
            })}
        </Carousel>
    );
}

function SectionFaculty({ selectedSection }) {
    if (!selectedSection) {
        return;
    }
    return (
        <div>
            <p
                style={{
                    marginBottom: '0px',
                }}
            >
                Faculty Teaching:
            </p>
            <Title
                style={{
                    marginTop: '0px',
                    paddingTop: '0px',
                    marginBottom: '15px',
                }}
                level={5}
            >
                {selectedSection?.faculties?.map((faculty) => faculty.name).join(', ') ??
                    'No faculty assigned'}
            </Title>
        </div>
    );
}

function KosCalender({ selectedSection }) {
    const localizer = dayjsLocalizer(dayjs);
    const [events, setEvents] = useState();
    const [trigger, { isLoading, error }] = useLazyGetAllFromKosQuery();

    useEffect(() => {
        if (!selectedSection) {
            return;
        }
        trigger({
            endpoint: 'course_catalogue/schedule_section_item',
            params: {
                section_id: selectedSection.id,
            },
        })
            .unwrap()
            .then((res) => {
                setEvents(
                    res?.schedule_section_items?.map((item) => ({
                        start: dayjs(item.from_time).toDate(),
                        end: dayjs(item.to_time).toDate(),
                        title: item.venue_name,
                        ...item,
                    })),
                );
            })
            .catch((err) => console.error(err));
    }, [selectedSection, trigger]);

    if (!selectedSection) {
        return;
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error</div>;
    }

    if (events === undefined) {
        return;
    }

    if (events.length === 0) {
        return <Title level={5}>No events</Title>;
    }

    return (
        <Calendar
            localizer={localizer}
            events={events}
            style={{
                height: '600px',
                width: '100%',
                marginTop: '10px',
            }}
            formats={{
                agendaHeaderFormat: (date) => {
                    return (
                        dayjs(date.start).format('DD-MM-YYYY') +
                        ' - ' +
                        dayjs(date.end).format('DD-MM-YYYY')
                    );
                },
            }}
            defaultDate={events?.[0]?.start}
        />
    );
}

export default CalenderContent;
