import KosLogo from '../assets/VULogo.png';
import { Button, Image, Typography } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonNavBar from '../assets/PersonNavbar.svg';
import './MainHeader.css';
const { Title } = Typography;

function MainHeader({ hideName = false }) {
    const navigate = useNavigate();
    const UserData = useMemo(
        () => JSON.parse(localStorage.getItem('data') || '{}'),
        [localStorage.getItem('data')],
    );

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px 0',
                marginTop: '-5px',
            }}
        >
            <Image
                src={KosLogo}
                alt='logo'
                style={{
                    width: '180px',
                    height: '60px',

                    cursor: 'pointer',
                }}
                preview={false}
                className='KosLogoOnlyForDesktop'
            />
            {UserData?.firstName && hideName === false ? (
                <div className='UserNameAndLogoutButton'>
                    <Image src={PersonNavBar} preview={false} />
                    <div
                        style={{
                            marginLeft: '10px',
                        }}
                    >
                        <Title
                            level={5}
                            style={{
                                margin: '0',
                            }}
                        >
                            {UserData?.firstName}
                        </Title>
                        <Title
                            level={5}
                            style={{
                                margin: '0',
                            }}
                        >
                            {UserData?.lastName}
                        </Title>
                    </div>
                    <Button
                        type='default'
                        onClick={() => {
                            localStorage.removeItem('data');
                            navigate('/');
                        }}
                        style={{
                            marginLeft: '15px',
                        }}
                    >
                        Logout
                    </Button>
                </div>
            ) : null}
        </div>
    );
}

export default MainHeader;
