import { useNavigate } from 'react-router-dom';
import { CoursePageContext, useCoursePage } from './CoursePageApiAndContext';
import CourseFilter from './components/CourseFilter';
import Courses from './components/Courses';
import './CoursePage.css';

function CoursePage() {
    const navigate = useNavigate();
    if (localStorage.getItem('data') === null) {
        navigate('/');
    }
    const api = useCoursePage();
    return (
        <CoursePageContext.Provider value={api}>
            <CoursePageSubComponent />
        </CoursePageContext.Provider>
    );
}

function CoursePageSubComponent() {
    return (
        <div className='courseFilterAndCourseView'>
            <div className='courseFilterHideAboveALimit'>
                <CourseFilter />
            </div>
            <Courses />
        </div>
    );
}

export default CoursePage;
